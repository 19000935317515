.confirmDisclosures-hintBox {
  .boxWithTooltip {
    display: flex;
    border-bottom: 2px solid #e8e9ee;
    border-top: 2px solid #e8e9ee;
  }

  .form-group .boxWithTooltip .info-icon-wrapper {
    padding-top: 8px;
  }
}

.hint-box {
  .field-boolean {
    position: relative;
  }
  .boxWithTooltip::before {
    position: absolute;
    width: 94%;
    height: 98%;
    border-right: solid 2px;
    border-top: solid 2px;
    border-color: #ffffff;
    right: 0px;
    top: 0px;
    content: '';
  }

  .boxWithTooltip {
    display: flex;
    border: solid 2px #e8e9ee;
  }

  .form-group .boxWithTooltip .info-icon-wrapper {
    padding-top: 15px;
    padding-left: 30px;
    z-index: 1;
  }
}
