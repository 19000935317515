.tile-row {
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  flex-flow: row wrap;
  .tile-row_info-wrapper {
    &.MuiPaper-root {
      width: 100%;
      border: 2px solid #e8e9ee;
      border-radius: 0;
    }
    .tile-row_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      text-transform: uppercase;
      flex-wrap: wrap;
      row-gap: 1rem;
      .tile-row_icon {
        height: 72px;
        width: 72px;
        svg {
          margin-bottom: 0;
        }
      }
      .tile-row_info {
        flex: 1;
        padding: 0 15px;
        letter-spacing: 4px;
        word-break: break-word;
        .tile-row_heading2.MuiTypography-root {
          font-family: 'Lato';
          font-weight: 900;
          font-size: 1.25rem;
          line-height: 1.625rem;
          letter-spacing: 0.063em;
        }
        .tile-row_heading1.MuiTypography-root {
          font-family: 'Lato';
          font-weight: 900;
          font-size: 0.813rem;
          line-height: 1.625rem;
          letter-spacing: 0.25em;
          color: #55566d;
        }
        .tile-row_divider {
          &.MuiDivider-root {
            border-color: #e8e9ee;
            border-bottom-width: 2px;
            margin: 8px 0;
          }
        }
      }
      .tile-row_switch-wrapper {
        .MuiFormControlLabel-root {
          margin-left: 1.5rem;
          margin-right: 0;
        }
        .MuiTypography-root {
          font-family: 'Lato';
          font-weight: 800;
          font-size: 0.875rem;
          line-height: 1.625rem;
          letter-spacing: 0.063em;
          margin-right: 1.5rem;
        }
      }
      .tile-row_button {
        width: 60px;
        height: 60px;
        background-color: #cfd0d5;
        &:hover {
          background-color: #cfd0d5;
          box-shadow: none;
        }
      }
    }
  }
  &.tile-row_secondary {
    .tile-row_info-wrapper {
      &.MuiPaper-root {
        background-color: #f1f2f5;
        border: none;
      }
      .tile-row_info {
        .tile-row_heading1.MuiTypography-root {
          letter-spacing: 0.125em;
          color: #55566d;
        }
        .tile-row_divider {
          &.MuiDivider-root {
            border-color: #e2e2e9;
          }
        }
      }
    }
  }
}
