.confirmDisclosures-hintBox {
  .field-error {
    color: red; //#eb0000
    .boxWithTooltip {
      border-bottom: none;
      border-top: 2px solid red;
    }
    .MuiBox-root .MuiGrid-root {
      // line under the checkbox component
      border-bottom: 2px solid white;
    }
    .checkbox-widget {
      border: solid 2px red;
    }

    .checkbox-label {
      color: red;
      .MuiCheckbox-root svg {
        color: red;
      }
    }
    // .info-icon-wrapper .MuiSvgIcon-root{
    //   padding-bottom: 122px;
    //   border-bottom: 2px solid red;
    // }
    .info-icon-wrapper::before {
      position: absolute;
      width: 4%;
      border-bottom: solid 2px;
      border-color: white;
      right: 0px;
      top: 162px;
      content: '';
    }
  }
  .field-disabled {
    color: silver;
    .boxWithTooltip {
      border-bottom: none;
      border-top: 2px solid silver;
    }
    .MuiBox-root .MuiGrid-root {
      border-bottom: 2px solid silver;
    }
    .checkbox-label {
      color: silver;
      .MuiCheckbox-root svg {
        color: silver;
      }
    }
    .info-icon-wrapper::before {
      position: absolute;
      width: 4%;
      border-bottom: solid 2px;
      border-color: silver;
      right: 0px;
      top: 162px;
      content: '';
    }
  }
  .field-boolean {
    position: relative;
  }

  .boxWithTooltip {
    border-bottom: none;
  }
  .checkbox-widget::before {
    position: absolute;
    width: 30%;
    height: 97%;
    border-right: solid 5px;
    border-bottom: solid 5px;
    border-color: white;
    right: -3px;
    bottom: -3px;
    content: '';
  }

  .checkbox-widget {
    border-radius: 0px;
    border: solid 2px #e8e9ee;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .checkbox-label {
    padding: 20px;
    color: #55566d;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    word-break: break-word;
  }
  .MuiFormControlLabel-label {
    margin-left: 70px;
  }
}

.MuiFormGroup-root {
  flex-direction: row !important;
  align-items: center;
  a {
    color: #7300ff;
  }
}

.hint-box {
  .checkbox-label {
    padding-left: 10px;
    z-index: 1;
  }

  .checkbox-label {
    .MuiTypography-root {
      padding-left: 10px;
      font-size: 20px;
      font-weight: 500;
    }
  }
}

.checkbox-widget .Mui-checked {
  // border: 4px solid black;
  border-radius: 0;
  padding: 0px;
  margin: 9px;
}

.hint-box {
  .field-error {
    color: #eb0000;

    .boxWithTooltip {
      border: none;
      .MuiFormGroup-root {
        border: 2px solid #eb0000;
      }
      .boxWithTooltip::before {
        content: none;
      }
      .MuiGrid-root {
        position: relative;
      }
      .MuiFormGroup-root::before {
        position: absolute;
        width: 94%;
        height: 98%;
        border-right: solid 2px;
        border-top: solid 2px;
        border-color: #ffffff;
        right: 0px;
        top: 0px;
        content: '';
      }
      .info-icon-wrapper::before {
        position: absolute;
        width: 150%;
        border-bottom: solid 2px;
        border-color: white;
        right: -25px;
        top: 56px;
        content: '';
      }
    }
    .MuiCheckbox-root svg {
      color: #eb0000;
    }
  }
}

@media screen and (max-width: 768px) {
  .confirmDisclosures-hintBox {
    .checkbox-label {
      padding: 15px 0 20px 20px;
    }
  }
  .checkbox-label {
    span.MuiTypography-root {
      font-size: 12px;
      margin-left: 50px;
    }
  }
}
