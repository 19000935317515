.input-file-container {
  position: relative;
  height: 64px;
  width: max-content;
}
.input-file-trigger {
  display: block;
  font-size: 1em;
  cursor: pointer;
  height: 100%;
}
.input-file {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.more-info {
  background-color: #efefef;
  padding: 10px 20px;
  margin-top: 15px;
}

.file-name,
.file-input-action,
.input-file-trigger {
  display: inline-block;
}

.input-file-container {
  display: flex;
  .border-decor {
    width: 16px;
    height: 100%;
    border-top: 2px solid #d3d4da;
    border-left: 2px solid #d3d4da;
    display: inline-block;
  }
  .input-file {
    z-index: 5;
    width: 100%;
  }
  .input-file-trigger {
    display: flex;
    align-items: center;
    z-index: 0;
    .file-name {
      margin-left: 12px;
      margin-right: 8px;
      padding-right: 12px;
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-thickness: 1px;
      position: relative;
      transform-style: preserve-3d;
      font-size: 1.2em;
      font-weight: 700;
      font-family: 'Lato';
      letter-spacing: 1px;
      line-height: 26px;
      .green-underline {
        width: 100%;
        height: 6px;
        position: absolute;
        bottom: 1px;
        background-color: #6cf96a;
        transform: translateZ(-10px);
      }
      @media screen and (max-width: 480px) {
        font-size: 1em;
      }
    }
  }
  .file-input-action {
    position: relative;
    z-index: 10;
    display: flex;
    button {
      cursor: pointer;
      border: none;
      background-color: transparent;
    }
  }
}
