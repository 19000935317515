.label-with-icon-field::before {
  border-top: solid 2px;
  position: absolute;
  width: 98%;
  height: 70%;
  border-right: solid 4px;
  border-color: rgb(255, 255, 255);
  right: -2px;
  content: '';
  top: -2px;
}
.label-with-icon-field {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 1px;
  text-underline-offset: 2px;
  .MuiTypography-root {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 1px;
    text-underline-offset: 2px;
  }
}
