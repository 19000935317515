.id-scan-modal-root {
  &.MuiModal-root {
    overflow: auto;
  }
}
.id-scan-wrapper {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.id-scan-modal-wrapper {
  padding: 2rem;
  background-color: white;
}

.id-scan-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
}

.id-scan-spacer {
  margin: 8px;
}

.id-scan-image-column {
  display: flex;
  flex-direction: column;
}

.id-scan-image-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1rem;
}

.id-scan-image {
  width: 100%;
  height: 100%;
}

.id-scan-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.875rem;
}
