.app-wrapper {
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // background: #ffffff;
  &.overflow-hidden {
    overflow: hidden;
  }
  .microcopy-label > p {
    margin: 0px;
  }

  .quote-wrapper {
    .page-background {
      @media screen and (min-width: 1536px) {
        background-image: url('../../public/background-circles.svg');
        background-position: right calc(100vh - 405px), 0 100%, 100% 0;
        background-repeat: no-repeat;
      }
      &.page-background_cubes {
        @media screen and (min-width: 900px) {
          background-image: url('../../public/account-bg-2.svg'),
            url('../../public/account-bg-3.svg');
          background-position: 0 100%, 100% 0;
          background-repeat: no-repeat;
        }
        @media screen and (min-width: 1536px) {
          background-image: url('../../public/background-circles.svg'),
            url('../../public/account-bg-2.svg'),
            url('../../public/account-bg-3.svg');
          background-position: right calc(100vh - 405px), 0 100%, 100% 0;
          background-repeat: no-repeat;
        }
      }
    }
    .page-wrapper {
      // background: #ffffff;
      padding: 2rem;

      @media only screen and (min-width: 900px) {
        max-width: 57.563rem;
        padding: 6em 2.25em 1em 2.25em;
      }
      @media only screen and (min-width: 1024px) {
        padding: 1.8em 2.25em 1em 2.25em;
      }
      /* Global CSS */
      .hidden {
        display: none;
      }
      .hide-hr {
        hr {
          display: none;
        }
      }

      .position-inherit {
        position: inherit !important;
      }
      .white-space-inherit {
        white-space: inherit !important;
      }
      .transform-inherit {
        transform: inherit !important;
      }
      .xs_display-hidden {
        @media only screen and (max-width: 599px) {
          display: none;
        }
      }
      .sm_display-hidden {
        @media only screen and (max-width: 899px) {
          display: none;
        }
      }
      .md_display-hidden {
        @media only screen and (max-width: 1199px) {
          display: none;
        }
      }
      .lg_display-hidden {
        @media only screen and (max-width: 1535px) {
          display: none;
        }
      }
      .sm_up_display-hidden {
        @media only screen and (min-width: 600px) {
          display: none;
        }
        .xs-md_display-hidden {
          @media only screen and (max-width: 1023px) {
            display: none;
          }
        }
      }
      .font-weight-bold {
        font-weight: bold;
      }

      .label-divider {
        border-bottom: 1px solid #000;
        margin-top: 0.5rem;
      }
      .p-1 {
        padding: 1rem !important;
      }
      .m-1 {
        margin: 1rem !important;
      }
      // right
      .mr-0_25 {
        margin-right: 0.25rem !important;
      }
      // bottom
      .mb-1 {
        margin-bottom: 1rem !important;
      }
      .mb-1_5 {
        margin-bottom: 1.5rem !important;
      }
      .mb-2 {
        margin-bottom: 2rem !important;
      }
      // left
      .ml-0_25 {
        margin-left: 0.25rem !important;
      }
      .ml-5 {
        margin-left: 5rem !important;
      }
      // top
      .mt-1 {
        margin-top: 1rem !important;
      }

      .flex {
        display: flex;
      }
      .flex-align-items-center {
        align-items: center;
      }

      .center {
        justify-content: center;
      }

      .cursor-pointer {
        cursor: pointer;
      }

      .sr-only:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }

      .date-picker_open {
        .MuiInputAdornment-root .MuiButtonBase-root {
          background-color: #000;
          svg path {
            fill: var(--brandColor);
          }
        }
      }
      .btn-mui-custom {
        border: 1px solid #e8e9ee;
        color: #000;
        background-color: #e8e9ee;
      }

      /* RJSF Forms */
      .rjsf {
        width: 100%;
        .MuiFormControl-root {
          width: 100%;
        }
        .has-error:not(.field-object),
        .has-danger:not(.field-object) {
          .MuiOutlinedInput-notchedOutline {
            border-color: #eb0000;
          }
          .MuiInputBase-input,
          .MuiInputLabel-root {
            color: #eb0000;
          }
        }
        // .Mui-error,
        // .Mui-error .MuiInputLabel-root,
        // .Mui-error .MuiOutlinedInput-notchedOutline {
        //   color: #EB0000;
        //   border-color: #EB0000;
        // }
        .help-block {
          margin: 0;
        }
        .tooltip {
          cursor: help;
        }
        ul.error-detail {
          list-style-type: none;
          margin: 0;
          padding: 0;
          li.text-danger {
            padding: 10px 0;
            text-transform: none;
            color: #eb0000;
            font-family: 'Lato';
            font-size: 13px;
            line-height: 15.6px;
          }
        }
        .toggle-button-class {
          border: 1px solid;
          display: flex;
          &.selected {
            background-color: rgb(128, 126, 126);
          }
        }

        .form-field {
          display: flex;
          flex-direction: column;
        }

        .icon-button {
          width: unset;
          border: 0;
          box-shadow: none;
          background-color: white;
        }
        div[role='radiogroup'] {
          .MuiTypography-root > p {
            margin: 0;
            display: inline;
          }
        }
      }

      @media (min-width: 1200px) {
        .toggle-button-alignment {
          justify-content: right;
          max-width: 99%;
        }
      }

      @media (max-width: 465px) {
        .toggle-button-size {
          min-width: 128px;
          height: 64px;
        }
      }
    }
    .coverage-icon {
      width: 75%;
      @media screen and (min-width: 900px) {
        width: 100%;
      }
    }
    .enroll-telematics {
      .MuiTypography-root {
        color: #55566d;
        letter-spacing: 1px;
        &.Mui-error {
          p {
            strong {
              color: #eb0000;
            }
          }
        }
        p {
          strong {
            color: #000;
          }
        }
      }
    }
  }
  .MuiCheckbox-root {
    svg {
      width: 32px;
      height: 32px;
    }
  }
  .congratulation {
    padding: 0px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  .disclosures-wrapper {
    .page-wrapper {
      .MuiAccordion-region {
        .MuiTypography-body1 {
          font-size: 1.1rem;
          font-weight: 500;
          letter-spacing: 0.063em;
          line-height: 1.8rem;
        }
      }
    }
    .MuiCheckbox-root {
      &.Mui-checked {
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
    .download-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      padding-left: 10px;
      height: 56px;
      background: #f1f2f5;
      border-radius: 6px;
      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1;

      .icon {
        width: 20px;
        height: 22px;
        margin-right: 5px;

        /* SF / Body - Semibold */

        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        /* identical to box height, or 129% */

        text-align: right;
        letter-spacing: -0.408px;

        /* Light Shade 5 */

        color: #9087a7;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
      }
      a {
        text-underline-position: under;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .header-wrapper {
    position: relative;
    @media screen and (min-width: 1024px) {
      max-width: 450px;
    }
    @media screen and (min-width: 1336px) {
      max-width: 647px;
    }
    @media screen and (min-width: 1440px) {
      max-width: 700px;
    }
    @media screen and (min-width: 1536px) {
      max-width: 824px;
    }
    &.header-underline_drum:after {
      content: '';
      display: none;
      @media screen and (min-width: 1024px) {
        background-image: url('../../public/drum.svg');
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        bottom: 0;
        right: -46px;
        transform: translate(50%, 50%);
        width: 51px;
        height: 51px;
        z-index: 1;
      }
    }
  }
  .header-underline {
    position: relative;
    left: -6.25rem;
    width: 100vw;
    height: 0.25rem;
    background-color: #000;
    @media screen and (max-width: 900px) {
      left: -4rem;
    }
  }

  .plan-option-underline {
    position: relative;
    width: 108%;
    height: 0.25rem;
    background-color: #000;
    margin-left: -8%;
  }

  .rich-text_doc-body {
    & b,
    & strong {
      font-weight: 700;
      color: black;
    }
    & ol,
    & ul {
      list-style-position: inside;
      padding-left: 0;
      li::marker {
        font-weight: 700;
        color: black;
      }
      li:not(:last-child) {
        margin-bottom: 1.375rem;
      }
    }
  }
  .MuiAccordionDetails-root .rich-text_doc-body {
    font-family: 'Lato';
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.675rem;
    letter-spacing: 0.063em;
    color: #55566d;
  }

  .hr-double {
    width: 100%;
    border-top: 2px solid black;
    padding-bottom: 2px;
    position: relative;
    &:after {
      content: '';
      display: block;
      background-color: #000;
      width: 90%;
      height: 1px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.text-line-bottom {
  padding-bottom: 2.25rem;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 2.5rem;
    height: 0.25rem;
    background-color: #000;
    position: absolute;
    bottom: 0;
  }
}

.text-line-bottom.no-padding {
  padding-bottom: unset;
}

.overlap-image-ubi {
  position: relative;
  top: -55px;
  left: -15px;
  margin-bottom: -55px;
}

.w-full {
  width: 100% !important;
}
.w-unset {
  width: unset;
}
.h-full {
  height: 100% !important;
}
.novo-hyperlink {
  text-decoration: underline !important;
  color: #292c3d !important;
  &:hover {
    background-color: white !important;
  }
}

.found-existing-application {
  .microcopy-label > p {
    margin: 0;
  }
}

.policy-details-info-box {
  .microcopy-label {
    display: inline-block;
  }
}

.payment-and-next-steps {
  .microcopy-label {
    & > p {
      margin: 0.5rem;
      line-height: 26px;
    }
    & > hr {
      margin: 1rem 0rem;
      border-width: 0;
      border-color: #e8e9ee;
      border-bottom-width: 4px;
    }
    p,
    ul {
      font-family: Lato;
      color: #55566d;
    }
  }
}

.text-line-top_small {
  padding: 1rem;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.25rem;
    background-color: #000;
    position: absolute;
    top: 0;
  }
  @media screen and (min-width: 1024px) {
    padding-top: 1.75rem;
  }
}

.switch {
  .MuiSwitch-track {
    padding-right: 40px;
    padding-bottom: 12px;
    margin: -5px;
    margin-top: -6px;
    border-radius: 25px;
    opacity: inherit !important;
  }
  .MuiSwitch-switchBase {
    .MuiSwitch-thumb {
      background-color: #ccc !important;
    }
  }
  .Mui-checked {
    .MuiSwitch-thumb {
      background-color: #6cf96a !important;
    }
  }
}
.Mui-disabled {
  .switch {
    .MuiSwitch-track {
      background-color: #ccc !important;
    }
  }
}

.confirmDisclosures {
  .MuiFormControlLabel-root {
    align-items: flex-start;
  }
  .MuiCheckbox-root {
    padding-top: 0;
  }
}

.quote-field {
  .MuiFormControl-root {
    width: 100%;
  }
}

.MuiButtonBase-root.MuiRadio-root {
  color: #000;
}
.novo-radio-button {
  color: #737391;
  position: relative;
  border-color: #e8e9ee;
  &.Mui-error {
    border-color: #eb0000;
  }
  &.MuiBox-root {
    border: solid 2px #e8e9ee;
    border-radius: 0;
  }
  &.radio-button-checked {
    color: #000;
  }

  .MuiSvgIcon-root {
    width: 36px;
    height: 36px;
  }
}

.novo-radio-button.MuiBox-root.radio-button-yes:before {
  content: '';
  border-top: solid 2px;
  position: absolute;
  width: 80%;
  height: 70%;
  border-left: solid 2px;
  border-color: #fff;
  left: -2px;
  top: -2px;
}

.novo-radio-button.MuiBox-root.radio-button-no:before {
  content: '';
  border-top: solid 2px;
  position: absolute;
  width: 80%;
  height: 65%;
  border-right: solid 2px;
  border-color: #fff;
  right: -2px;
  top: -2px;
}
.checkbox-style1 {
  & > .MuiGrid-root {
    color: #737391 !important;
    position: relative;
    border: solid 2px #e8e9ee;
    border-radius: 0;
    &:before {
      content: '';
      border-top: solid 2px;
      position: absolute;
      width: 95%;
      height: 100%;
      border-right: solid 2px;
      border-color: #fff;
      right: -2px;
      top: -2px;
    }
    .MuiFormControlLabel-label {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.625rem;
      letter-spacing: 0.063em;
      z-index: 1;
      color: #55566d;
    }
    .Mui-checked + .MuiFormControlLabel-label {
      font-weight: 600;
      color: #55566d;
    }
  }
  .MuiCheckbox-root {
    margin-left: 10px;
  }
  &.MuiGrid-root > .MuiGrid-item {
    padding-top: 0;
  }

  .Mui-checked {
    svg {
      // border: 4px solid;
      width: 32px;
      height: 32px;
    }
  }
}

.MuiGrid-root > .checkbox-style1.MuiGrid-item {
  padding-top: 0;
}

.novo-button.MuiButton-root,
.novo-button.MuiButton-root:hover {
  & > svg {
    margin-right: 24px;
  }
  &.social-button {
    width: 100%;
    min-width: 15.375rem;
    color: white;
    @media screen and (min-width: 1024px) {
      width: unset;
    }
  }
}
.button-hr {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  .MuiDivider-root {
    width: 100%;
    border-color: rgb(0, 0, 0);
    margin-top: 0px;
  }
}

.mui-loader {
  position: fixed;
  top: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  background: rgba(27, 28, 34, 0.88);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-price-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.plan-description.MuiGrid-root {
  margin-top: 1rem;
  border-left: 12px solid;
  padding-left: 2rem;
}

.info-icon-wrapper {
  .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 768px) {
  .payment-box-info.MuiGrid-root {
    width: auto;
  }
  .payment-box-items.MuiGrid-root {
    width: 48%;
  }
}

.field-boolean {
  .MuiGrid-root {
    .MuiBox-root {
      .text-danger {
        text-align: left;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .field-boolean {
    .MuiBox-root {
      .text-danger {
        text-align: end;
        padding-right: 17%;
      }
    }
  }
}
:root {
  --brandColor: #6cf96a;
}

.side-drawer-container {
  .rjsf {
    width: 100%;
    .MuiFormControl-root {
      width: 100%;
    }
    .has-error:not(.field-object),
    .has-danger:not(.field-object) {
      .MuiOutlinedInput-notchedOutline {
        border-color: #eb0000;
      }
      .MuiInputBase-input,
      .MuiInputLabel-root {
        color: #eb0000;
      }
    }
  }
}

.toggle-radio-button {
  border-top: solid 2px #d3d4da;
  //padding-top: 12px;
}

.toggle-radio-button-span {
  background: #d3d4da;
  width: 120px;
  height: 2px;
  //margin-top: 10px;
}

.plan-options-wrapper {
  .plan-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    .plan-price {
      justify-content: center;
      display: flex;
    }
  }
}

.plan-option {
  .policy-effective-date {
    padding-top: 10px;
    color: #55566d;
  }
  .billing-cycle {
    padding-top: 10px;
    color: #55566d;
  }
}

.plan-telematics {
  margin-bottom: -30px;
}

.plan-option-final-payment-grid {
  align-items: center;
  bottom: 0;
  z-index: 1;
  background: #fff;
  &.plan-option-final-payment-grid-sticky {
    position: fixed;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -ms-transition: all 0.4s;
  }
  .plan-option-button-grid {
    padding-left: 1.5rem;
    padding-top: 0;
    .plan-option-button {
      text-align: left;
      display: flex;
      padding-left: 1.938rem;
      padding-right: 2.813rem;
    }
  }
  .plan-options-footer-wrapper {
    width: 100%;
    max-width: 59.563rem;
    display: flex;
    align-items: center;
    padding: 2rem 0;
  }
  .payment-detail {
    padding-left: 1.5rem;
    hr {
      width: 100%;
      border-color: rgb(0, 0, 0);
      border-width: thin;
      margin: 1rem 0;
    }
    p {
      line-height: 28px;
    }
  }
}

.toggle-button-alignment {
  margin-top: 10px;
}

.carousel .thumbs-wrapper {
  display: none;
}
@media (max-width: 1200px) {
  .congratulation-grid {
    hr {
      display: none;
    }
  }
  .plan-option-final-payment-grid {
    padding: 0 !important;
    .payment-detail {
      padding-top: 30px;
      hr {
        width: 90% !important;
      }
    }
    .plan-option-button-grid {
      padding-left: 0 !important;
      width: 100%;
    }
    .css-wr4v8e-MuiTypography-root {
      font-size: 12px;
      padding-top: 0;
    }
  }
}

@media (min-width: 900px) and (max-width: 1300px) {
  .plan-option-final-payment-grid {
    .plan-options-footer-wrapper {
      width: 60%;
    }
  }
}

@media (max-width: 900px) {
  .checkbox-style1 {
    .MuiCheckbox-root {
      padding-top: 0;
    }
    .MuiGrid-root {
      .MuiFormControlLabel-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 1px;
        padding-top: 15px;
      }
    }
  }

  .form-group {
    .MuiBox-root {
      input {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  .disclosures-wrapper {
    .tile-accent-borders_bodyWrapper {
      .tile-accent-borders_heading,
      .tile-accent-borders_content {
        font-size: 16px !important;
        line-height: 18px !important;
        letter-spacing: 4px !important;
      }
      .tile-accent-borders_content {
        margin-top: 5px;
        padding-left: 30px;
        .super-text {
          font-size: 0.8rem;
        }
      }
    }
    .title-1-small {
      font-size: 1.3rem;
      padding-right: 0;
    }
    .title-1 {
      font-size: 32px !important;
      line-height: 48px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .title-2 {
      font-size: 16px !important;
      line-height: 18px !important;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .review-history-container {
    .tile-row_item {
      .tile-row_heading2,
      .tile-row_heading1 {
        line-height: 22px !important;
        letter-spacing: 2px !important;
      }
    }
    .label-with-icon-field {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .toggle-radio-button {
    padding-top: 16px;
    .toggle-radio-button-span {
      margin-top: 10px;
    }
  }
  .toggle-button-label {
    h5 {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px !important;
    }
  }
  .plan-option {
    .plan-descriptions {
      margin-bottom: 40px;
      h5 {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #55566d;
      }
    }
    .heading-title {
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 3px !important;
    }
    .policy-effective-date {
      padding-top: 0 !important;
    }
    .billing-cycle {
      padding-top: 0 !important;
    }
    .page_footer {
      display: none;
    }
    .page-wrapper {
      padding-bottom: 65px !important;
      //padding: 0rem 3rem!important;
    }
  }

  .plan-option-final-payment-grid {
    left: 0;
    align-items: stretch;
    .payment-detail {
      .value {
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.5px;
      }
      .method-type {
        line-height: 26px;
        letter-spacing: 0.5px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .app-wrapper {
    .payment-box-items.MuiGrid-root {
      margin: 20px 0;
      width: 100%;
    }
    .MuiCheckbox-root svg {
      width: 24px;
      height: 24px;
    }
    .info-icon-wrapper .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
    .plan-option-underline {
      margin-left: 0;
      width: 100%;
    }
    .plan-options-wrapper {
      padding-bottom: 0;
    }
    .plan-option-final-payment-grid {
      position: fixed;
      .plan-options-footer-wrapper {
        padding: 0;
        padding-left: 1rem;
      }
      .plan-option-button-grid {
        .plan-option-button {
          height: 100%;
          padding: 0.75rem;
        }
      }
      .payment-detail {
        padding-top: 0;
        hr {
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 514px) {
  .plan-option-final-payment-grid
    .plan-option-button-grid
    .plan-option-button
    span.MuiButton-endIcon {
    align-self: end;
  }
}

.MuiFormControl-root {
  .MuiOutlinedInput-root {
    font-size: 18px;
    line-height: 24px;
    height: 64px;
    .MuiSelect-select {
      font-size: 18px;
      line-height: 24px;
    }
  }
  label {
    color: #292d66;
    opacity: 0.68;
    font-size: 18px;
    font-weight: 600;
  }
  .MuiSelect-select {
    color: #292c3d;
  }
  .MuiSelect-icon {
    height: 100%;
  }
}

.MuiPaper-root {
  .MuiList-root {
    border: solid 1px #000;
    padding: 0;
  }
  ul {
    li {
      padding: 1.5rem;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-transform: capitalize;
      color: #292c3d;
    }
    li.MuiAutocomplete-option {
      text-transform: none;
    }
  }
}

.form-group {
  .MuiBox-root {
    .empty-value {
      .MuiInputLabel-root[data-shrink='true'] {
        -webkit-transform: translate(14px, 16px) scale(1);
        text-transform: capitalize;
      }
      fieldset.MuiOutlinedInput-notchedOutline legend span {
        display: none;
      }
    }
  }
}

.pb-150 {
  padding-bottom: 150px;
}

.installment-fee {
  padding-top: 20px;
  color: #55566d;
}

.review-history-form {
  border-left: solid 2px #e9ebf0;
  border-bottom: solid 2px #cfd0d5;
  padding: 18px 0 30px 22px;
  .inner-form-grid {
    padding-top: 12px !important;
  }
}

.driver-license-title {
  padding-left: 32px;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: 'Lato';
  font-weight: 900;
  font-size: 20px;
  line-height: 1.5rem;
  letter-spacing: 0.188em;
  border-left: solid 8px black;
  @media screen and (min-width: 1024px) {
    line-height: 2.5rem;
    font-size: 1.25rem;
    letter-spacing: 0.25em;
  }
}
