.container_tile {
  .tile_wrapper {
    &.MuiPaper-root {
      &.tile_variant1 {
        &.read-only {
          cursor: not-allowed;
          &:hover {
            border-color: #e8e9ee;
            & .mode_add svg {
              rect {
                fill: #e8e9ee;
              }
              path {
                fill: #000000;
              }
            }
          }
        }
      }
    }
  }
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 16px;
  padding-top: 12px;
  margin-bottom: 2.25rem;
}
.tile_wrapper.MuiPaper-root {
  --offset: 12px;
  min-height: 223px;
  border-color: #e8e9ee;
  border-width: 2px;
  border-radius: 0px;
  margin-top: var(--offset);
  padding: 0 24px calc(24px - var(--offset)) 24px;
  &.tile_variant1 {
    height: 70%;
    transition: border-color 0.2s ease-in;
    svg rect,
    svg path {
      transition: fill 0.2s ease-in;
    }
    &.edit-mode {
      border-bottom: none;
    }
    &.add-mode {
      @media screen and (max-width: 685px) {
        min-height: unset;
        padding-bottom: 20px;
      }
      &:hover {
        border: 2px solid #000;
        & .mode_add svg {
          rect {
            fill: #000;
          }
          path {
            fill: var(--brandColor);
          }
        }
      }
    }
    @media screen and (min-width: 683px) {
      .mode_add .tile_info {
        max-width: 5.877rem;
      }
    }
  }
  &.tile_variant2 {
    display: flex;
    flex-direction: column;
    margin-top: 43px;
    color: #000;
    width: unset;
    /* max-width: 225px; */
    & .MuiTypography-root {
      color: #000;
    }
    &.tile_selected {
      background-color: #000;
      background-image: url('../../public/no-vo-bg.svg');
      background-repeat: no-repeat;
      background-position: -18px center;
      color: white;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      border-top: none;
      border-left: none;
      position: relative;
      transform-style: preserve-3d;
      & .MuiTypography-root {
        color: white;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        // background-image: url('../../public/stripe-frame.svg');
        background-repeat: no-repeat;
        background-position: bottom right;
        right: -10px;
        bottom: -10px;
        transform: translateZ(-1px);
      }
      .tile_selected--cube-stack {
        position: absolute;
        right: -17px;
        top: -44px;
      }
      .tile_selected--cube {
        position: absolute;
        bottom: -1px;
        left: 0;
        transform: translateX(-50%);
      }
    }
    .MuiDivider-root {
      --margin: 12px;
      margin-top: var(--margin);
      margin-bottom: var(--margin);
      width: 48px;
      border-bottom-width: 2px;
      border-color: currentColor;
    }
  }
  .MuiTypography-root {
    overflow-wrap: break-word;
  }
  .tile_info-wrapper {
    position: relative;
    top: calc(var(--offset) * -1);
    width: 100%;
    height: 100%;
  }
  &.tile_variant1 .MuiTypography-root {
    font-family: 'Lato';
    font-size: 1.25rem;
    line-height: 1.625rem;
    letter-spacing: 0.063em;
  }
  &.tile_variant2 .MuiTypography-root {
    font-weight: 500;
    font-family: 'Rubik';
    font-size: 1.75rem;
    line-height: 1.625rem;
    letter-spacing: 0.063em;
    text-align: center;
  }
  &.tile_variant1 .tile_progress-label.MuiTypography-root {
    font-size: 0.875rem;
    font-weight: 900;
    text-transform: uppercase;
  }
}

.remove-reason-dialog {
  .button-group {
    padding-top: 2rem;
    justify-content: flex-end;
    .no-hover:hover {
      background-color: transparent;
    }
  }
}

.edit-remove-component {
  .MuiButton-outlined {
    border-top: 2px;
    border-bottom: 2px;
    border-style: solid;
    border-color: #e8e9ee;
    &:hover {
      border-color: #000;
      background-color: transparent;
    }
  }
  .edit-button {
    border-left: 2px;
    border-style: solid;
    border-color: #e8e9ee;
  }
  .remove-button {
    border-right: 2px;
    border-style: solid;
    border-color: #e8e9ee;
  }
  .microcopy-label {
    font-family: Lato;
    font-weight: 500;
    text-transform: none;
  }
}

.quote-sub-wrapper {
  flex-grow: 1;
}

.quote-wrappers {
  max-width: 255px;
  @media screen and (max-width: 590px) {
    max-width: unset;
  }
}
