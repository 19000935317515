/* .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  background-color: white;
}

.header-link {
  color: rgba(34, 34, 34, 1);
  text-decoration: none;
  font-weight: 800;
  margin-left: 1.8125em;
  white-space: nowrap;
} 
.logo-full {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media only screen and (max-width: 961px) {
    display: none;
  }
}

.logo-small {
  display: none;
  @media only screen and (max-width: 960px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.novo-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 15rem;
}
*/

.btn-header.MuiButton-containedSecondary {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 24px;
  text-transform: none;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.063em;
  border-radius: 0;
  padding-left: 1rem !important;
  padding-right: 1.1rem !important;
  padding-bottom: 1rem !important;
  height: 100%;
  letter-spacing: 0.01em;
  line-height: 1.5;
  @media screen and (min-width: 900px) {
    font-size: 1.25rem;
    padding: 24px;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
    padding-bottom: 24px !important;
    height: auto;
    align-items: unset;
  }
  .MuiButton-startIcon {
    display: none;
    justify-content: end;
    flex: 0 0 100%;
    @media screen and (min-width: 1024px) {
      display: flex;
    }
  }
  .btn-header_label {
    display: flex;
    @media screen and (min-width: 1440px) {
      margin-right: 50px;
    }
  }
}
