.TabsUnstyled-horizontal {
  width: 100%;
}

// div[role="tabpanel"] .rjsf .page-header-h5 {
//   color: #000;
//   font-family: 'Lato';
//   font-style: normal;
//   font-weight: 900;
//   font-size: 20px;
//   line-height: 40px;
//   letter-spacing: 4px;
//   text-transform: uppercase;
// }
