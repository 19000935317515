.large-radio-button-widget {
  .MuiBox-root {
    .toggle-radio-button {
      border-top: none;
      row-gap: unset;
      .toggle-button-label {
        font-family: 'Lato';
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 1px;
        color: #5a5b72;
        flex-basis: 100%;
        padding-top: 12px;
        @media (min-width: 1200px) {
          max-width: unset !important;
        }
      }
      .toggle-button-content {
        padding-top: 0;
      }
      .MuiGrid-item .toggle-button-alignment {
        justify-content: left;
        .novo-radio-button {
          border: none;
        }
      }
    }
  }
  .toggle-radio-button-span {
    display: none;
  }
}
