.mobile-nav::-webkit-scrollbar {
  display: none;
}
.mobile-nav {
  padding-left: 2rem;
  padding-right: 4rem;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 2rem;
  overflow-x: scroll;
  // scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  align-items: center;
  gap: 3rem;
  h1 {
    margin: 0;
    font-size: 1.5rem;
    line-height: 1.6rem;
    letter-spacing: 0.063em;
  }
  a {
    text-decoration: none;
    scroll-snap-align: start;
    min-width: max-content;
    &:active,
    &:hover,
    &:focus {
      .mobile-nav-item {
        color: #000000 !important;
      }
    }
    &[aria-disabled='true'] {
      pointer-events: none; /* Disable link */
    }
  }
}

.mobile-nav-item {
  scroll-snap-align: start;
  min-width: max-content;
  font-family: 'Rubik';
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.6rem;
  letter-spacing: 0.063em;
  text-transform: uppercase;
  position: relative;
  z-index: 0;
  padding-bottom: 3px;
  color: #767894;
  display: flex;
  .slash-icon {
    margin-right: 1rem;
    position: absolute;
    left: -1.9rem;
    top: -9px;
  }
}

.activeMobileNavItem {
  color: #000000;
  &:before {
    width: 105%;
    height: 8px;
    content: '';
    display: block;
    background-color: #6cf96a;
    position: absolute;
    bottom: 4px;
    left: 1px;
    z-index: -1;
  }
  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    bottom: 4px;
    right: -17px;
    background-color: #000000;
    z-index: 1;
  }
}
