.logo-full {
  padding-left: 2rem;
  display: flex;
  align-items: flex-end;
  svg {
    width: 5.6rem;
    padding-bottom: 0.6rem;
  }
  @media screen and (min-width: 900px) {
    padding-top: 2.25rem;
    padding-left: 0;
    display: block;
    svg {
      width: 7.5rem;
      padding: 0;
    }
  }
}
.MuiGrid-root.left-nav {
  background-color: transparent;
  width: 100%;
  min-height: unset;
  height: max-content;
  .left-nav_fixed {
    width: 100vw;
    .left-nav_footerWrapper {
      display: none;
    }
  }
  @media screen and (min-width: 900px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: 50px fit-content(1em) auto fit-content(1em) 52px;
    flex-basis: calc((558 / 1920) * 100%);
    min-height: 100vh;
    height: inherit;
    background-color: #f1f2f5;
    .left-nav_fixed {
      width: calc((558 / 1920) * 100vw);
      position: fixed;
      max-width: calc(25vw - 2.25rem);
      .left-nav_footerWrapper {
        display: block;
      }
      @media screen and (max-height: 737px) {
        position: static;
        background-position: center center;
        .left-nav_wrapper {
          justify-content: flex-start;
          height: 100%;
          .left-nav_footerWrapper {
            flex: 1 1 0;
          }
        }
      }
      @media screen and (max-width: 1181px) {
        position: static;
        background-position: center center;
        .left-nav_wrapper {
          justify-content: flex-start;
          height: 100%;
          .left-nav_footerWrapper {
            flex: 1 1 0;
          }
        }
      }
    }
  }
}
