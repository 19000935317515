.recon-tile-container {
  display: flex;
  position: relative;
  transform-style: preserve-3d;
  margin-bottom: 20px;

  .offset-background {
    position: absolute;
    top: 8px;
    left: 12px;
    width: 100%;
    height: 100%;
    transform: translateZ(-10px);
  }
}

@media screen and (min-width: 1000px) {
  .half-width {
    width: 50%;
    .offset-background {
      left: 8px;
    }
  }
}

.recon-tile {
  background-color: #f2f1f5;
  padding: 20px 36px;
  flex: 1;
  border-right: 2px solid white;

  p,
  h3 {
    font-family: 'Lato';
    margin: 0;
  }

  h3 {
    color: #5a5b72;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 22px;
    font-weight: 900;
  }

  .heading1 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 4px;
    margin: 8px 0 4px 0;
  }

  .heading2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 1px;
    color: #5a5b72;
  }
}
