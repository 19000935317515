.side-drawer-container.MuiGrid-root {
  margin: 2rem;
  .MuiAlert-root {
    margin-bottom: 1.5rem;
  }
}

.novo-icon-button {
  border: 2px solid black;
  border-radius: 0;
}

.novo-icon-button .MuiIconButton-root {
  padding: 18px 17.76px;
}

.novo-icon-button .MuiSvgIcon-root {
  height: 2rem;
  width: 2rem;
  color: black;
}

.novo-slide-drawer .MuiDrawer-paper {
  border: 10px solid #000000;
  box-sizing: border-box;
  background-image: url('../../public/novo-stroke-banner.svg'),
    url('../../public/novo-bg2-sidebar.svg');
  background-position: center center, 50% 0%;
  ul.error-detail {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .text-danger {
      color: #eb0000;
    }
  }
  .alert-component {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .novo-slide-drawer .MuiDrawer-paper {
    max-width: 542px;
    border: 10px solid #000000;
    box-sizing: border-box;
  }

  .side-drawer-container.MuiGrid-root {
    margin: 5rem;
    margin-top: 2rem;
  }
}
