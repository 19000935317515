body {
  margin: 0;
  font-family: 'Rubik', 'RubikBold', 'RubikRegular', 'Lato', 'LatoRegular',
    'LatoBold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
  color: #000000;
  font-size: 16px;
  line-height: 1.5em;

  background-color: #f1f2f5;
  @media only screen and (max-width: 960px) {
    background-color: white;
  }

  a {
    color: #000000;
  }
}

.app-wrapper > .quote-wrapper {
  margin-bottom: 0;
}

.quote-wrapper {
  //margin-bottom: 2rem;
  .button-container {
    display: flex;
    justify-content: center;
  }
  .back-arrow {
    justify-content: flex-start;
  }
}

.quote-wrapper-new {
  margin-bottom: 2rem;
  .MuiGrid-item,
  .button-container {
    display: flex;
  }
  .back-arrow {
    justify-content: flex-start;
  }
  .remove-button {
    height: 20px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:disabled {
  pointer-events: none;
}

@keyframes move {
  90% {
    opacity: 1;
    width: 6px;
    height: 6px;
  }
  100% {
    motion-offset: 100%;
    offset-distance: 100%;
    opacity: 0;
    width: 4px;
    height: 4px;
  }
}

@keyframes draw {
  20% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.arrow {
  display: flex;
  position: relative;
  height: 40px;
  justify-content: flex-end;
  transform: translateY(3px);
  svg:not(.longer) {
    width: 33px;
    @media screen and (min-width: 900px) {
      width: 40px;
    }
  }
}

.longer {
  position: absolute;
  left: 22px;
  top: 1px;
  transform: scale(0);
  stroke-width: 2px;
}

.mover {
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 100%;
  left: 12px;
  top: 5px;
  position: absolute;
  @media screen and (min-width: 900px) {
    width: 6px;
    height: 6px;
    left: 14px;
    top: 3px;
    position: absolute;
  }
}
.btn-arrow {
  column-gap: 8px;
  @media screen and (min-width: 900px) {
    column-gap: 0;
  }
}
.btn-arrow:hover:not(:disabled) .longer {
  @media screen and (min-width: 900px) {
    animation: draw 0.2s ease-out forwards;
    animation-delay: 0.3s;
    transform-origin: right bottom;
  }
}

.btn-arrow:hover:not(:disabled) .mover {
  @media screen and (min-width: 900px) {
    offset-path: path(
      'M1 0.99999C5.33333 0.833324 13 2.5 8.5 12C13 2.5 18.5 4.00003 22 13'
    );
    motion-rotation: reverse;
    animation: move 0.5s ease-out forwards;
  }
}
