.places-auto-complete-dropdown-wrapper {
  position: absolute;
  z-index: 2;
  background: white;
  padding: 1em;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 4px;
}

.google-option {
  padding: 1em 0;
  border-radius: 4px;
}

.active-option {
  background-color: rgba(127, 37, 217, 0.4);
  cursor: pointer;
  padding: 1rem;
}
