.car .btn-arrow {
  width: 198px !important;
  height: 60px !important;
}

.car-form {
  margin-top: 25px;
}

.search-car {
  .MuiGrid-container {
    .page-wrapper {
      .rjsf {
        .Mui-disabled {
          background-color: #d3d4da;
        }
        .MuiBox-root {
          h5 {
            font-weight: 900;
            line-height: 40px;
            letter-spacing: 4px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
