.plan-options-list.MuiGrid-root {
  display: none;
  margin-left: unset;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (min-width: 664px) {
    display: flex;
  }
}

.plan-options_carousel.MuiGrid-root {
  display: flex;
  flex-grow: 1;
  @media screen and (min-width: 664px) {
    display: none;
  }
}

.carousel .slider-wrapper {
  overflow-x: scroll !important;
}

.plan-options_carousel {
  margin-left: -2rem;
  margin-right: -2rem;
}

.plan-options-carousel {
  @media screen and(max-width: 664px) {
    display: inline-flex;
    overflow: hidden;
    overflow-x: scroll;
    column-gap: 26px;
    flex-grow: 1;
    padding: 0 2rem;
    scroll-snap-type: x;
  }
}

.plan-options-carousel .MuiGrid-item {
  @media screen and(max-width: 664px) {
    min-width: 255px;
    scroll-snap-align: center;
  }
}

.sticky-class-wrapper {
  width: 100%;
  @media screen and(max-width: 664px) {
    margin-top: 43px;
  }
}

.premium-total {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #9e9fa3;
  text-align: center;
  margin-top: 14px;
}

.carousel-root.plan-options-carousel {
  width: 100vw;
  margin: 0 auto;
  @media screen and (min-width: 400px) {
    transform: none;
  }
}

.control-dots {
  margin-left: 1.5rem;
  position: relative;
  display: flex;
  list-style: none;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 30px;
  & > li {
    cursor: pointer;
    margin-right: 7.5px;
    margin-left: 7.5px;
    display: flex;
    align-items: center;
    transform-style: preserve-3d;
    &:not(.is-selected) {
      background-color: #767894;
      width: 20px;
      height: 4px;
      border: none;
      border-bottom: 2px solid white;
    }
    &.is-selected {
      border: 2px solid white;
      background-color: black;
      width: 78px;
      height: 8px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        display: block;
        background-color: var(--brandColor);
        width: 100%;
        height: 8px;
        top: -4px;
        left: -5px;
        transform: translateZ(-1px);
      }
    }
  }
}
