.quote-wrapper .MuiStepper-root {
  & > .left-nav_step {
    display: grid;
    position: relative;
    grid-template-columns: 40px;
    grid-template-rows: auto;
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.063em;
    text-decoration: none;
    margin-bottom: 1.225rem;
    cursor: default;
    color: #737391;
    pointer-events: none; /* Disable link */
    &:not(.incomplete) {
      color: #000;
      cursor: pointer;
      pointer-events: all; /* Enable link */
      .step-border {
        .MuiStep-root {
          cursor: pointer;
          pointer-events: all; /* Enable */
          .MuiStepLabel-root {
            height: max-content; /* Shows full label*/
            overflow-y: visible; /* Shows full label*/
          }
        }
      }
    }
    &.active {
      &:before {
        background-color: #6cf96a;
      }
    }
    &.complete {
      &:before {
        background-color: #fff;
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 60px;
      background-color: transparent;
    }
    .step-border {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        left: 6px;
        width: 40px;
        height: 3px;
        background-color: currentColor;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        left: 6px;
        width: 3px;
        height: 60px;
        background-color: currentColor;
      }
      .MuiStep-root {
        display: grid;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-template-columns: 15px auto;
        grid-template-rows: 13px 20px auto;
        box-sizing: border-box;
        position: relative;
        margin: 6px 0 0 6px;
        width: 40px;
        min-height: max-content;
        cursor: default;
        pointer-events: none; /* Disable */
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 60px;
          left: 0;
          width: 11px;
          height: 3px;
          background-color: currentColor;
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 3px;
          height: 26px;
          background-color: currentColor;
        }
        .step-icon {
          grid-column-start: 2;
          grid-row-start: 2;
          line-height: 1;
          font-weight: 500;
          font-size: 1rem;
        }
        .MuiStepLabel-root {
          grid-column-start: 2;
          grid-row-start: 3;
          padding: 0;
          word-spacing: 100vw;
          display: block;
          height: max-content; /* Shows full label */
          // height: 30px; /* Partially hides of label*/
          // overflow-y: clip; /* Partially hides of label*/
          .MuiStepLabel-labelContainer {
            padding: 0;
            color: currentColor;
            .MuiStepLabel-label {
              margin: 0;
              font-weight: 500;
              color: currentColor;
              font-size: 1.25rem;
              line-height: 1.375rem;
              min-height: max-content;
              text-align: left !important;
            }
          }
        }
      }
    }
  }
}

.MuiStepConnector-line {
  min-height: 0px !important;
}
