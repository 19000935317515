.MuiAccordionSummary-expandIconWrapper {
  .MuiSvgIcon-root {
    background: #e1e3e2;
    width: 1.7em;
    height: 1.7em;
  }
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  padding: 0px 0px;
  .MuiAccordionSummary-content {
    margin: 30px 0;
  }
}

.MuiTypography-root.Accordion-title {
  margin: 17px 0px 17px;
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.MuiTypography-root.reconciliation-title {
  padding-left: 32px;
  margin-top: 8px;
}

.MuiTypography-root.reconciliation-title.purple {
  border-left: solid 8px #6b04eb;
}

.MuiTypography-root.reconciliation-title.black {
  border-left: solid 8px black;
}

.MuiTypography-root.reconciliation-title.orange {
  border-left: solid 8px #f47500;
}

.MuiCollapse-vertical {
  width: 100%;
  color: '#000';
}

.MuiPaper-root.MuiAccordion-root.Mui-disabled {
  background-color: white;
  box-shadow: none;
  border-bottom: 0px solid;
  .MuiAccordionSummary-expandIconWrapper {
    display: none;
  }
}

.accordion-done {
  border-bottom: 2px solid #000;
}

.MuiPaper-root.MuiAccordion-root.accordion-done::before {
  position: absolute;
  border-bottom: 0px solid;
  content: '';
}

.MuiPaper-root.MuiAccordion-root {
  box-shadow: none;
  .MuiDivider-root {
    display: none;
  }
  &.Mui-expanded {
    margin: 0;
    &:last-child {
      border-bottom: 0px solid;
    }
    .MuiDivider-root {
      display: block;
      border-color: #000;
    }
  }
  &.disabled::before {
    bottom: 0;
    //top: 99px;
    content: '';
  }
  &.accordion-open::before {
    opacity: 1;
  }
}

.Container {
  border-bottom: 0px solid #e0e0e0;
}

.MuiButton-root {
  width: 190px;
}

/* Driver Row Cards component*/
.driver-info-wrapper {
  &.MuiPaper-root {
    width: 100%;
    border: 2px solid #e8e9ee;
    border-radius: 0;
    margin: 0 8px;
  }
  .driver-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    .driver-info {
      flex: 1;
      padding: 0 15px;
      font-family: lato;
      letter-spacing: 4px;
      .driver-type {
        &.MuiTypography-root {
          color: #55566d;
        }
      }
      .driver-name {
        &.MuiTypography-root {
          color: #000;
        }
      }
      .driver-divider {
        &.MuiDivider-root {
          border: 1px solid #e8e9ee;
          margin: 8px 0;
        }
      }
    }
    .driver-include {
      .MuiTypography-root {
        font-family: 'Lato';
        font-size: 14px;
        font-weight: 800;
        line-height: 26px;
        letter-spacing: 1px;
      }
    }
  }
}

.coverage-section {
  &.MuiGrid-root {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    margin: 0 8px;
  }
  form {
    flex: 1;
  }
  .coverage-dropdown {
    .MuiFormControl-root {
      margin: 1rem auto;
      .MuiInputBase-root {
        border-radius: 0;
      }
    }
  }
}
.car-divider-grid {
  &.MuiGrid-root {
    margin: 8px;
  }
  .car-divider {
    &.MuiDivider-root {
      border: 1px solid #e8e9ee;
      margin-top: 1rem;
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
}
