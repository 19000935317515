.pseudo-border {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  background-color: #e8e8e8;
}
.tile-accent-borders.MuiGrid-root {
  margin-left: 0;
  position: relative;
  padding: 0.875rem 0 0 0;
  &:before {
    @extend .pseudo-border;
    top: 0;
    left: 0;
    width: 90%;
    max-width: 388px;
  }
  .MuiGrid-root {
    margin: 0;
  }
  .MuiGrid-root > .MuiGrid-item {
    padding: 0;
  }
  p {
    margin: 0;
  }
  .tile-accent-borders_bodyWrapper {
    border-right: 2px solid #e8e8e8;
    padding: 1rem 1.875rem 0.625rem 0;
    position: relative;
    &:before {
      @extend .pseudo-border;
      top: 0;
      right: 0;
      width: 160px;
    }
    &:after {
      @extend .pseudo-border;
      bottom: 0;
      right: 0;
      width: 28px;
    }
    .tile-accent-borders_heading {
      font-family: 'Lato';
      font-weight: 900;
      font-size: 1.25rem;
      line-height: 2.5rem;
      letter-spacing: 0.25em;
      text-transform: uppercase;
    }
    .tile-accent-borders_content {
      font-family: 'Lato';
      font-weight: 900;
      font-size: 1.25rem;
      line-height: 2.5rem;
      letter-spacing: 0.25em;
      .super-text {
        vertical-align: super;
        font-size: 1rem;
        line-height: 0;
      }
    }
  }
  .tile-accent-borders_caption-wrapper {
    width: 100%;
    display: flex;
    column-gap: 2rem;
    align-items: flex-end;
    .tile-accent-borders_caption {
      font-family: 'Lato';
      font-weight: 900;
      font-size: 0.813rem;
      line-height: 1rem;
      text-align: right;
      letter-spacing: 0.125em;
      text-transform: uppercase;
      color: #55566d;
    }
    .tile-accent-borders_divider {
      height: 2px;
      background-color: #e8e8e8;
      flex: 1 1 0;
      margin-bottom: 0.125rem;
    }
  }
}

.payment-box-items {
  .tile-accent-borders {
    display: flex;
    // flex-direction: column;
  }
}
