.footer-wrapper {
  display: flex;
  align-items: flex-end;
  height: 100%;
  flex-wrap: nowrap;
  font-family: 'Lato';
  font-weight: 500;
  font-size: 0.813rem;
  line-height: 1rem;
  letter-spacing: 0.03125em;
  color: #55566d;
  strong {
    font-weight: 700;
    color: #000;
  }
  @media screen and (min-width: 900px) {
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: 600;
    letter-spacing: 0.0625em;
    strong {
      font-weight: 800;
    }
  }
}

.footer-copyright {
  display: flex;
  flex-direction: column;
}

.footer-version {
  color: #000;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0.0625em;
}
