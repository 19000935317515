.novo-radio-button:hover {
  cursor: pointer;
}
.novo-radio-button {
  &.Mui-error {
    border-color: #eb0000;
    color: #eb0000;
    & .MuiButtonBase-root.MuiRadio-root {
      color: #eb0000;
    }
  }
}
