.cube-cont {
  position: fixed;
  z-index: 999;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(27, 28, 34, 0.88);

  --offset: 57px;
  .svg-wrap {
    transform-origin: center center;
    position: absolute;
    transform: scale(0.5)
      translate(calc(-50% - var(--offset)), calc(-50% - var(--offset)));
    top: calc(50% - var(--offset));
    left: calc(50% - var(--offset));
    @media (min-width: 900px) {
      transform: scale(1) translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
  > .microcopy-label {
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    left: 50%;
    top: calc(50% + 125px);
    @media (min-width: 900px) {
      top: calc(50% + 150px);
    }
    font-size: x-large;
    // line-height: 1.7em;
    color: white;
    width: calc(100% - 3rem);
  }
}
